import { zuicy_logo_symbol, zuicy_logo_text } from '@assets';
import styles from './HomeHeader.module.scss';

const HomeHeader = () => {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <img className={styles.zuicy_logo_symbol} src={zuicy_logo_symbol} alt='zuicy symbol logo' />
        <img className={styles.zuicy_logo_text} src={zuicy_logo_text} alt='zuicy Text logo' />
      </header>
    </section>
  );
};

export default HomeHeader;
