import styles from './CompanyInfo.module.scss';

import { Strings } from '@strings';

const CompanyInfo = () => {
  return (
    <section className={styles.container}>
      <div className={styles.address}>
        <span className={styles.text}>{Strings.FOOTER_ADDRESS}</span>
        <div className={`${styles.divider} ${styles.mobile}`} />
        <span className={styles.text}>{Strings.FOOTER_BUSINESS_REGISTRATION_NUMBER}</span>
      </div>
      <span className={styles.text}>{Strings.FOOTER_COPYRIGHT}</span>
    </section>
  );
};

export default CompanyInfo;
