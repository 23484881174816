import { Text } from '@components';

import {
  mockup_phone_interactions,
  interaction_expression,
  interaction_face,
  interaction_hand_detection,
  interaction_mouth_open,
  interaction_sight,
  interaction_touch,
  interaction_turn,
  interaction_voice,
} from '@assets';

import { Colors } from '@styles';
import styles from './InteractionSection.module.scss';

import { Strings } from '@strings';
import { Fragment } from 'react';

const INTERACTION_ICONS_A = [
  interaction_turn,
  interaction_hand_detection,
  interaction_sight,
  interaction_face,
];
const INTERACTION_ICONS_B = [
  interaction_voice,
  interaction_mouth_open,
  interaction_touch,
  interaction_expression,
];

const InteractionSection = () => {
  return (
    <section className={styles.background}>
      <div className={styles.mask} />
      <div className={styles.container}>
        <img
          className={`${styles.desktop} ${styles.image}`}
          src={mockup_phone_interactions}
          alt='mockup_phone_interactions'
        />
        <div className={styles.content}>
          <Text.Head className={styles.desktop} bold color={Colors.white}>
            {Strings.INTERACTION_SECTION_HEAD}
          </Text.Head>
          <Text.Head className={styles.mobile} bold color={Colors.white}>
            {Strings.INTERACTION_SECTION_HEAD_MOBILE}
          </Text.Head>

          <Text.Body color={Colors.gray500}>{Strings.INTERACTION_SECTION_BODY}</Text.Body>

          <div className={styles.image_container}>
            <img
              className={`${styles.mobile} ${styles.image}`}
              src={mockup_phone_interactions}
              alt='mockup_phone_interactions'
            />
          </div>

          <div className={styles.interactions}>
            <div className={styles.interactions_mobile}>
              {INTERACTION_ICONS_A.map((each, idx) => (
                <Fragment key={idx}>
                  <div className={styles.interaction}>
                    <img
                      className={styles.icon}
                      src={each}
                      alt={Strings.INTERACTION_SECTION_INTERACTIONS_A[idx]}
                    />
                    <span className={styles.text}>
                      {Strings.INTERACTION_SECTION_INTERACTIONS_A[idx]}
                    </span>
                  </div>
                  <div
                    className={`${styles.divider} ${
                      idx === INTERACTION_ICONS_A.length - 1 && styles.dividerMobile
                    }`}
                  />
                </Fragment>
              ))}
            </div>
            <div className={styles.interactions_mobile}>
              {INTERACTION_ICONS_B.map((each, idx) => (
                <Fragment key={idx}>
                  <div className={styles.interaction}>
                    <img
                      className={styles.icon}
                      src={each}
                      alt={Strings.INTERACTION_SECTION_INTERACTIONS_B[idx]}
                    />
                    <span className={styles.text}>
                      {Strings.INTERACTION_SECTION_INTERACTIONS_B[idx]}
                    </span>
                  </div>
                  {idx < INTERACTION_ICONS_B.length - 1 && <div className={styles.divider} />}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InteractionSection;
