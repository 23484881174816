import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Footer, HomeHeader, ScrollToTop } from '@components';

import { Home } from '@pages';

import { Urls } from '@utils';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HomeHeader />
      <Routes>
        <Route path={Urls.HOME} element={<Home />} />
        {/* 404 Redirect */}
        <Route path='/*' element={<Navigate to='/'></Navigate>}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
