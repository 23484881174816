import { Text } from '@components';

import { StoreDownloadButton, StoreType } from './components';

import { mockup_phone_bubbles, zuicy_logo_text } from '@assets';

import { Colors } from '@styles';
import styles from './StoreDownloadSection.module.scss';

import { Strings } from '@strings';

const StoreDownloadSection = () => {
  return (
    <section className={styles.background}>
      <article className={styles.container}>
        <div className={styles.content}>
          <Text.Head className={styles.desktop} color={Colors.white} bold>
            {Strings.STORE_DOWNLOAD_SECTION_HEAD}
            <img className={styles.logo} src={zuicy_logo_text} alt={'zuicy_logo_text'} />
          </Text.Head>

          <Text.Head className={styles.mobile} color={Colors.white} bold>
            {Strings.STORE_DOWNLOAD_SECTION_HEAD_MOBILE}
          </Text.Head>
          <img
            className={`${styles.mobile} ${styles.logo}`}
            src={zuicy_logo_text}
            alt={'zuicy_logo_text'}
          />

          <Text.Body color={Colors.gray500}>{Strings.STORE_DOWNLOAD_SECTION_BODY}</Text.Body>
          <section className={`${styles.desktop} ${styles.buttons}`}>
            <StoreDownloadButton storeType={StoreType.APP_STORE} />
            <StoreDownloadButton storeType={StoreType.GOOGLE_PLAY} />
          </section>
        </div>
        <img
          className={styles.image}
          src={mockup_phone_bubbles}
          alt='mockup_phone_bubbles_mockup'
        />
        <section className={`${styles.tablet} ${styles.buttons}`}>
          <StoreDownloadButton storeType={StoreType.APP_STORE} />
          <StoreDownloadButton storeType={StoreType.GOOGLE_PLAY} />
        </section>
      </article>
    </section>
  );
};

export default StoreDownloadSection;
