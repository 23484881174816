export const TermsUrls = {
  PRIVACY: 'https://genesislab.notion.site/fce3f3e2e9b8487199c74c663d9767bd?pvs=4',
  SERVICE: 'https://genesislab.notion.site/ZUICY-8559a82329c14ca0abf31e84f6d19888?pvs=4',
  CONTACT: 'mailto:contact@zuicy.ai',
};

export const StoreUrls = {
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=ai.genesislab.zuicy',
  APP_STORE:
    'https://apps.apple.com/kr/app/zuicy-%EC%9D%B8%ED%84%B0%EB%9E%99%ED%8B%B0%EB%B8%8Cai-%EC%BD%98%ED%85%90%EC%B8%A0-%EB%86%80%EC%9D%B4%ED%84%B0/id1585286792',
};

// ! Must Sync with "@styles/scss/mixins.scss"
export const MAX_WIDTH_TABLET = 1024;
export const MAX_WIDTH_MOBILE = 768;
