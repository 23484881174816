import { Fragment } from 'react';

import { InteractionSection, StoreDownloadSection, ThumbnailSection } from './components';

const Home = () => {
  return (
    <Fragment>
      <StoreDownloadSection />
      <InteractionSection />
      <ThumbnailSection />
    </Fragment>
  );
};

export default Home;
