import LocalizedStrings from 'react-localization';

import { PageStrings } from './pages';
import { ComponentStrings } from './components';

const ko = { ...PageStrings, ...ComponentStrings };

export const Strings = new LocalizedStrings({
  ko,
  'ko-KR': ko,
});
