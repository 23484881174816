import { Text } from '@components';

import { thumbnails } from '@assets';

import { Colors } from '@styles';
import styles from './ThumbnailSection.module.scss';

import { Strings } from '@strings';

const ThumbnailSection = () => {
  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Text.Head bold color={Colors.white}>
            {Strings.THUMBNAIL_SECTION_HEAD}
          </Text.Head>
          <Text.Body color={Colors.gray500}>{Strings.THUMBNAIL_SECTION_BODY}</Text.Body>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.mask} />
          <img className={styles.image} src={thumbnails} alt='thumbnails' />
        </div>
      </div>
    </section>
  );
};

export default ThumbnailSection;
