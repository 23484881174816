import { ReactNode } from 'react';

import styles from './Body.module.scss';

interface BodyProps {
  children: ReactNode;
  color?: string;

  medium?: boolean;
  bold?: boolean;
}
const Body = (props: BodyProps) => {
  const { children, color, medium, bold } = props;

  return (
    <span
      className={`${styles.body} ${medium && styles.medium} ${bold && styles.bold}`}
      style={{ color }}
    >
      {children}
    </span>
  );
};

export default Body;
