import { NoDecoAnchor } from '@components';
import { Strings } from '@strings';

import { TermsUrls } from '@utils';
import { Fragment } from 'react';

import styles from './Terms.module.scss';

const Terms = () => {
  const TermsUrlList = [TermsUrls.PRIVACY, TermsUrls.SERVICE, TermsUrls.CONTACT];

  return (
    <section className={styles.container}>
      {TermsUrlList.map((each, idx) => (
        <Fragment key={idx}>
          <NoDecoAnchor
            className={`${styles.anchor} ${idx === 0 && styles.bold}`}
            href={each}
            target='_blank'
            rel='noreferrer'
          >
            {Strings.FOOTER_TERMS[idx]}
          </NoDecoAnchor>
          {idx < TermsUrlList.length - 1 && <div className={styles.divider} />}
        </Fragment>
      ))}
    </section>
  );
};

export default Terms;
