import { AnchorHTMLAttributes } from 'react';

import styles from './NoDecoAnchor.module.scss';

interface NoDecoAnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  //
}
const NoDecoAnchor = (props: NoDecoAnchorProps) => {
  const { children, className } = props;

  return (
    <a {...props} className={`${styles.anchor} ${className}`}>
      {children}
    </a>
  );
};

export default NoDecoAnchor;
