// ! Must Sync with "@styles/scss/colors.scss"
export const Colors = {
  gray800: '#3a3644',
  gray500: '#958FA0',
  gray300: '#d9d3e6',
  gray300_opacity05: 'rgba(217, 211, 230, 0.5)',

  white: '#ffffff',
  black: '#000000',
};
