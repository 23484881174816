import { HtmlHTMLAttributes, ReactNode } from 'react';

import styles from './Head.module.scss';

interface HeadProps extends HtmlHTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
  color?: string;

  medium?: boolean;
  bold?: boolean;
}
const Head = (props: HeadProps) => {
  const { children, color, medium, bold, className, ...rest } = props;

  return (
    <h2
      className={`${styles.head} ${medium && styles.medium} ${bold && styles.bold} ${className}`}
      style={{ color }}
      {...rest}
    >
      {children}
    </h2>
  );
};

export default Head;
