import { StoreUrls } from '@utils';

import { app_store, app_store_mobile, google_play, google_play_mobile } from '@assets';

import styles from './StoreDownloadButton.module.scss';

export enum StoreType {
  APP_STORE,
  GOOGLE_PLAY,
}

interface StoreDownloadButtonProps {
  storeType: StoreType;
}
const StoreDownloadButton = (props: StoreDownloadButtonProps) => {
  const { storeType } = props;

  const getText = () => {
    switch (storeType) {
      case StoreType.APP_STORE:
        return 'App Store';
      case StoreType.GOOGLE_PLAY:
        return 'Google Play';
      default:
        return '';
    }
  };

  const getImageSrc = () => {
    switch (storeType) {
      case StoreType.APP_STORE:
        return app_store;
      case StoreType.GOOGLE_PLAY:
        return google_play;
      default:
        return '';
    }
  };

  const getStoreUrl = () => {
    switch (storeType) {
      case StoreType.APP_STORE:
        return StoreUrls.APP_STORE;
      case StoreType.GOOGLE_PLAY:
        return StoreUrls.GOOGLE_PLAY;
      default:
        return '';
    }
  };

  const getMobileImageSrc = () => {
    switch (storeType) {
      case StoreType.APP_STORE:
        return app_store_mobile;
      case StoreType.GOOGLE_PLAY:
        return google_play_mobile;
      default:
        return '';
    }
  };

  const logFacebookPixelEvent = () => {
    const content_name = storeType === StoreType.APP_STORE ? 'appStore' : 'googlePlay';

    // @ts-ignore-next-line
    fbq('track', 'ViewContent', { content_name });
  };

  return (
    <a className={styles.no_deco_anchor} href={getStoreUrl()} target='_blank' rel='noreferrer'>
      <button className={styles.button} onClick={logFacebookPixelEvent}>
        <img src={getImageSrc()} className={styles.icon} alt={getText()} />
        {getText()}
      </button>
      <img className={styles.button_image} src={getMobileImageSrc()} alt={getText()} />
    </a>
  );
};

export default StoreDownloadButton;
