import { CompanyInfo, Terms } from './components';

import { zuicy_logo_text } from '@assets';

import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <section className={styles.background}>
      <div className={styles.mask} />
      <div className={styles.container}>
        <img className={styles.zuicy_logo_text} src={zuicy_logo_text} alt='zuicy_logo_text' />
        <Terms />
        <CompanyInfo />
      </div>
    </section>
  );
};

export default Footer;
